
import { defineComponent } from "vue";
import { ErrorMessage, useField } from "vee-validate";
import CondicionesPrivacidadModal from "@/components/modals/CondicionesPrivacidad.vue";
import DeclaracionResponsableModal from "@/components/modals/DeclaracionResponsable.vue";
import { format } from "date-fns";
import { es } from "date-fns/locale";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
    CondicionesPrivacidadModal,
    DeclaracionResponsableModal,
  },
  setup() {
    const { value: politicaPrivacidad } = useField("politicaPrivacidad");
    const { value: declaracionResponsable } = useField(
      "declaracionResponsable"
    );
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: direccion } = useField("direccion");
    const { value: localidad } = useField("localidad");
    const dia =
      format(new Date(), "eeee", { locale: es }) +
      ", " +
      format(new Date(), "dd-MM-yyyy");

    return {
      politicaPrivacidad,
      declaracionResponsable,
      nombre,
      apellidos,
      dni,
      direccion,
      localidad,
      dia,
    };
  },
});
