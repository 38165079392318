
import comarcas from "@/core/data/comarcas";
import {
  municipios,
  municipiosProvinciasComarcas,
} from "@/core/data/municipiosProvinciasComarcas";
import tratamientos from "@/core/data/tratamiento";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import { ErrorMessage, useField } from "vee-validate";
import { computed, defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => (te(text) ? t(text) : text);

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);
    const datosContacto = computed(() => module.contacto);

    const { value: email } = useField("email");
    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: direccion } = useField("direccion");
    const { value: comarca } = useField("comarca");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: dni } = useField("dni");
    const { value: telefono } = useField("telefono");

    dni.value = datosContacto.value?.dni || "";
    telefono.value = datosContacto.value?.telefono || "";

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has("cm")) {
      const comarcaIdQr = urlParams.get("cm");
      if (comarcas.find((element) => element.id == comarcaIdQr)) {
        comarca.value = comarcaIdQr;
      }
    }

    watch(
      () => localidad.value,
      (val) => {
        let registro = municipiosProvinciasComarcas.find(
          (f) => f.municipioNombre === val
        );
        // Asignamos automaticamente la provincia y comarca
        comarca.value = registro?.comarcaId;
        provincia.value = registro?.provinciaNombre; // En IAM el ID de provincia es el nombre
      }
    );

    return {
      i18n,
      municipios,
      comarcas,
      tratamientos,
      email,
      tratamiento,
      nombre,
      apellidos,
      direccion,
      comarca,
      provincia,
      localidad,
      dni,
      telefono,
      datosContacto,
    };
  },
});
