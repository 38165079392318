
import { StepperComponent } from "@/assets/ts/components";
import Step1 from "@/components/wizard/familia/Step1.vue";
import Step2 from "@/components/wizard/familia/Step2.vue";
import Step3 from "@/components/wizard/familia/Step3.vue";
import Step4 from "@/components/wizard/familia/Step4.vue";
import { Actions } from "@/store/enums/StoreEnums";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import { IAMFile, ServicioMenor } from "@/types";
import { ElMessage } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { mapGetters, useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface IStep1 {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  direccion: string;
  comarca: string;
  provincia: string;
  localidad: string;
}

interface IStep2 {
  conIngresos: boolean | null;
  ingresos: number | null;
  familiaPrioritaria: boolean | null;
  libroFamilia: IAMFile[] | null;
  // declaracionIngresos: boolean;
  // declaracionFamiliaPrioritaria: boolean;
}

interface IStep3 {
  menores: ServicioMenor[];
}

interface IStep4 {
  politicaPrivacidad: boolean;
  declaracionResponsable: boolean;
}

interface CreateAccount extends IStep1, IStep2, IStep3, IStep4 {}

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },

  setup() {
    const { t, te } = useI18n();
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const route = useRoute();
    const token = route.query.act;

    const formData = ref<CreateAccount>({
      tratamiento: "",
      nombre: "",
      apellidos: "",
      dni: "",
      email: "",
      telefono: "",
      direccion: "",
      comarca: "",
      provincia: "",
      localidad: "",
      conIngresos: null,
      ingresos: null,
      // declaracionIngresos: false,
      familiaPrioritaria: null,
      // declaracionFamiliaPrioritaria: false,
      menores: [],
      libroFamilia: [],
      politicaPrivacidad: false,
      declaracionResponsable: false,
    });

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);

    onBeforeMount(() => {
      if (token) {
        module.fetchById({ formularioId: token as string });
      }
    });
    const datosServicio = computed(() => module.servicio);
    const datosContacto = computed(() => module.contacto);
    const comarcaId = ref<string>();
    const nombreActividad = ref<string>();

    watch(datosServicio, () => {
      if (!datosServicio.value) return;

      comarcaId.value = datosServicio.value.comarcaId;
      nombreActividad.value = datosServicio.value.nombre;
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
    });

    const i18n = (text) => (te(text) ? t(text) : text);

    const createAccountSchema = [
      // TODO: i18n para los labels? revisar https://github.com/jquense/yup#using-a-custom-locale-dictionary
      Yup.object({
        tratamiento: Yup.string().required().label(i18n("TratamientoLabel")),
        nombre: Yup.string().required().label(i18n("FamiliaNombreLabel")),
        apellidos: Yup.string().required().label(i18n("FamiliaApellidosLabel")),
        email: Yup.string()
          .required()
          .email()
          .matches(
            /^(")?(?:[^."])(?:(?:[.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[-\w]*\.){1,5}([A-Za-z]){2,9}$/,

            i18n("FamiliaEmailError")
          )
          .label(i18n("FamiliaEmailLabel")),
        direccion: Yup.string()
          .required()
          .label(i18n("DireccionDireccionLabel")),
        comarca: Yup.string().required().label(i18n("DireccionComarcaLabel")),
        provincia: Yup.string()
          .required()
          .label(i18n("DireccionProvinciaLabel")),
        localidad: Yup.string()
          .required()
          .label(i18n("DireccionLocalidadLabel")),
      }),
      Yup.object({
        numeroMenores: Yup.string().required().label(i18n("Número de menores")),
        tipologiaFamilia: Yup.string()
          .required()
          .label(i18n("Tipología de familia")),
        conIngresos: Yup.boolean()
          .typeError(i18n("errorFaltaOpciones"))
          .required(),
        ingresos: Yup.number()
          .typeError(i18n("errorFormato"))
          .when("conIngresos", {
            is: true,
            then: Yup.number()
              .typeError(i18n("errorFormato"))
              .required()
              .min(0),
            otherwise: Yup.number().typeError(i18n("errorFormato")).nullable(),
          }),
        familiaPrioritaria: Yup.boolean()
          .typeError(i18n("errorFaltaOpciones"))
          .required(),
        libroFamilia: Yup.array().min(1, i18n("errorFaltaArchivo")),
      }),
      Yup.object({
        menores: Yup.array().min(1, i18n("errorFaltaMenor")),
      }),
      Yup.object({
        politicaPrivacidad: Yup.boolean().isTrue(
          i18n("politicaPrivacidadValidacion")
        ),
        declaracionResponsable: Yup.boolean().isTrue(
          i18n("declaracionResponsableValidacion")
        ),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<
      IStep1 | IStep2 | IStep3 | IStep4
    >({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      if (
        totalSteps.value != null &&
        currentStepIndex.value + 1 >= totalSteps.value
      ) {
        return formSubmit(formData.value);
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = async (formData) => {
      if (token) {
        let codigo;
        if (datosContacto.value) {
          formData.dni = datosContacto.value.dni;
          formData.telefono = datosContacto.value.telefono;
          const contacto = { dni: "", telefono: "" };
          module.setContacto(contacto);
        }
        await store
          .dispatch(Actions.POST_FAMILIAS, formData)
          .then(async (codFamilia) => {
            try {
              let menores = formData.menores;
              formData.servicioProgramadoId = token;
              formData.codigoFamilia = codFamilia;
              for (let menor of menores) {
                formData.menores = [menor];
                await store
                  .dispatch(Actions.POST_ACTIVIDAD, formData)
                  .then((codActividad) => {
                    codigo = codActividad;
                  });
              }
            } catch (err) {
              console.error("Err", err);
              return ElMessage.error(t("formularioEnviadoError"));
            }
          });
        Swal.fire({
          html: t("formularioActividadEnviadoBody", { codigo }),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: i18n("cerrarBtn"),
          customClass: {
            confirmButton: "btn btn-lg btn-primary",
          },
        }).then(() => {
          window.location.href = "/#/home";
        });
      } else {
        store
          .dispatch(Actions.POST_FAMILIAS, formData)
          .then((codigo) => {
            Swal.fire({
              html: t("formularioEnviadoBody", { codigo }),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: i18n("cerrarBtn"),
              customClass: {
                confirmButton: "btn btn-lg btn-primary",
              },
            }).then(() => {
              window.location.href = "/#/home";
            });
          })
          .catch((err) => {
            console.error("Err", err);
            ElMessage.error(t("formularioEnviadoError"));
          });
      }
    };

    return {
      horizontalWizardRef,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
      token,
      nombreActividad,
    };
  },
  computed: {
    ...mapGetters(["isPendingForm"]),
  },
});
