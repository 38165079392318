
import FamiliaPrioritariaModal from "@/components/modals/FamiliaPrioritaria.vue";
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";
import { numeroMenoresOpciones } from "@/core/data/numeroMenores";
import { tipologiasFamilia } from "@/core/data/tipologiasFamilia";
import { IAMFile } from "@/types";
import imageCompression from "browser-image-compression";
import Dropzone from "dropzone";
import { ElNotification } from "element-plus";
import { ErrorMessage, useField } from "vee-validate";
import { defineComponent } from "vue";

export default defineComponent({
  name: "step-2",
  components: {
    ErrorMessage,
    FamiliaPrioritariaModal,
    CurrencyInput,
  },

  setup() {
    const { value: numeroMenores } = useField("numeroMenores");
    const { value: tipologiaFamilia } = useField("tipologiaFamilia");
    const { value: conIngresos } = useField("conIngresos");
    const { value: ingresos } = useField("ingresos");
    const { value: familiaPrioritaria } = useField("familiaPrioritaria");
    const { value: libroFamilia } = useField<Array<IAMFile>>("libroFamilia");

    return {
      numeroMenores,
      tipologiaFamilia,
      conIngresos,
      ingresos,
      familiaPrioritaria,
      libroFamilia,
      tipologiasFamilia,
      numeroMenoresOpciones,
    };
  },
  mounted() {
    const dropzone = new Dropzone("#kt_dropzonejs_libro_familia", {
      url: process.env.VUE_APP_API_URL,
      // The name that will be used to transfer the file
      paramName: "libroFamilia",
      maxFilesize: 10, // MB
      addRemoveLinks: true,
      uploadMultiple: true,
      autoProcessQueue: false,
      acceptedFiles: "image/*,application/pdf",
    });
    dropzone.on("addedfile", async (file) => {
      let fileToAdd = file.type.includes("image/")
        ? await this.compressImage(file)
        : file.type.includes("application/pdf")
        ? await this.compressPDF(file)
        : file;

      if (!fileToAdd.name) {
        fileToAdd.name = file.name;
      }
      if (fileToAdd.size > file.size) {
        fileToAdd = file;
      }
      this.includeFile(fileToAdd, dropzone);
    });
    dropzone.on("removedfile", this.deleteFile);
  },
  methods: {
    includeFile(file, dropzone) {
      const reader = new FileReader();
      return new Promise<void>((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const fileSize = file.size;
          const totalSize = this.libroFamilia.reduce(
            (previousValue, currentValue) =>
              previousValue + new Blob([currentValue.data]).size,
            fileSize
          );
          if (totalSize > 10000000) {
            dropzone.removeAllFiles();
            resolve();
            ElNotification.error({
              title: "Error",
              message:
                "El tamaño máximo de los archivos ha sido superado (10MB). Comprímelos antes de subirlos.",
              offset: 100,
            });
            return;
          }

          this.libroFamilia.push({
            nombre: file.name,
            data: reader.result?.toString() || "",
          });
          resolve();
        };
        reader.onerror = reject;
      });
    },
    deleteFile(file) {
      this.libroFamilia = this.libroFamilia.filter(function (item) {
        return item.nombre !== file.name;
      });
    },
    compressImage(file) {
      const compressionOptions = { maxSizeMB: 0.3 };
      return imageCompression(file, compressionOptions);
    },
    compressPDF(file) {
      return new Promise((resolve, reject) => {
        var tenet: string[] = [];

        var canvas = document.getElementById(
          "page_canvas"
        ) as HTMLCanvasElement;
        var ctx = canvas?.getContext("2d");

        var input_scale = 1;
        var input_quality = 0.5;
        var input_format = "image/jpeg";
        var pdfDoc: any = null;
        var pageRendering = false;
        var pageCount = 0;
        var pageNumPending: number[] = [];
        var pageScale = input_scale;
        var pageQuality = input_quality;
        var pageFormat = input_format;
        var imgData = {};
        var pdfName = "doc";

        const queueRenderPage = function (num) {
          if (pageRendering) {
            pageNumPending.push(num);
          } else {
            renderPage(num);
          }
        };
        function downloadAll() {
          for (let i = 1; i <= pageCount; i++) {
            queueRenderPage(i);
          }
        }

        function pdf2img(pdf_url) {
          readPDF(pdf_url).then(() => downloadAll());
        }

        function renderPage(num) {
          pageRendering = true;
          // Using promise to fetch the page
          pdfDoc.getPage(num).then(function (page) {
            var viewport = page.getViewport({
              scale: pageScale,
            });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = {
              canvasContext: ctx,
              viewport: viewport,
            };
            var renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(function () {
              const data = canvas.toDataURL(pageFormat, pageQuality);

              imgData[num] = data;
              pageRendering = false;

              if (pageNumPending !== null && pageNumPending.length != 0) {
                // New page rendering is pending
                renderPage(pageNumPending.shift());
              } else {
                if (Object.keys(imgData).length == pageCount) {
                  tenet.push(JSON.parse(JSON.stringify(imgData)));
                  checkFileProcessProgress();
                }
              }
            });
          });
        }
        function checkFileProcessProgress() {
          processImageData();
        }
        function generateMetadata(file) {
          pdfName = file["name"];
        }
        function onProcessInputPDF() {
          if (file) {
            generateMetadata(file);
          } else {
            return;
          }

          const reader = new FileReader();

          reader.onload = function (e) {
            var url = e.target?.result;
            pdf2img(url);
          };

          reader.readAsDataURL(file);
        }
        function getImgObj(data): Promise<HTMLImageElement> {
          return new Promise(function (resolve, reject) {
            var img = new Image();
            img.onload = function () {
              resolve(img);
            };
            img.src = data;
          });
        }

        async function processImageData() {
          var options = {
            autoFirstPage: false,
            compress: false,
          };

          const doc = new window.PDFDocument(options);

          doc.info = {
            Title: file.name,
            Author: "",
          };
          const stream = doc.pipe(window.blobStream());

          for (let k = 0; k < tenet.length; k++) {
            var imgData = tenet[k];

            for (let i = 1; i <= Object.keys(imgData).length; i++) {
              const img_data = await getImgObj(imgData[i]);
              doc.addPage({
                size: [img_data.width, img_data.height],
              });
              doc.image(img_data.src, 0, 0);
            }
          }
          doc.end();
          stream.on("finish", function () {
            var output_blob = stream.toBlob("application/pdf");
            resolve(output_blob);
          });
        }
        function readPDF(url) {
          return new Promise((resolve, reject) => {
            var loadingTask = window.pdfjsLib.getDocument(url);
            loadingTask.promise.then(
              function (pdfDoc_) {
                pdfDoc = pdfDoc_;
                resetPDFMetaStore(pdfDoc.numPages);
                resolve(1);
              },
              () => reject(1)
            );
          });
        }
        function resetPDFMetaStore(numPages) {
          pageCount = numPages;
          imgData = {};
          pageNumPending = [];
          pageScale = input_scale;
          pageQuality = input_quality;
          pageFormat = input_format;
        }

        onProcessInputPDF();
      });
    },
  },
});
